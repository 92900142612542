import ApiService from "../base/api.service";
import {ChannelFactory} from "@/services/boostpack/channel/channel_factory.type";
import {AbstractChannel} from "@/services/boostpack/channel/abstract_channel.type";
import {TelegramChannel} from "@/services/boostpack/channel/type/telegram_channel.type";
import {MailChannel} from "@/services/boostpack/channel/type/mail_channel.type";

const resource = '/channel';

class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;

	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const ChannelService = {
	get() {
		try {
			return ApiService.get(`${resource}`);
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	async getChannelById(id: number): Promise<AbstractChannel | TelegramChannel | MailChannel | null> {
		try {
			const response = await ApiService.get(`${resource}/${id}`);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const channelFactory = new ChannelFactory();
					return channelFactory.createChannelByResponse(responseData);
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	async getChannelStateById(uuid: string): Promise<boolean> {
		try {
			const response = await ApiService.get(`${resource}/active/${uuid}`);
			if (response) {
				const responseData = response.data.data;
				return responseData == true;
			} else {
				return false;
			}
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	async getChannelByReference(reference: string, type: string): Promise<AbstractChannel | TelegramChannel | MailChannel | null> {
		try {
			const payload = {
				reference: reference,
				type: type,
			};
			const response = await ApiService.post(`${resource}/reference`, payload);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const channelFactory = new ChannelFactory();
					return channelFactory.createChannelByResponse(responseData);
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	create(payload: any) {
		return ApiService.put(`${resource}`, payload);
	},

	activateChannel(payload: any) {
		return ApiService.post(`${resource}/activate`, payload);
	},

	resetPin(payload: any) {
		return ApiService.post(`${resource}/resetPin`, payload);
	},

	// update(payload: any, id: number) {
	// 	return ApiService.put(`${resource}/${id}`, payload);
	// },
	// delete(id: number) {
	// 	return ApiService.delete(`${resource}/${id}`)
	// },
}

export {ChannelService, ResponseError};