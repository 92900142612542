
import {defineComponent, ref} from 'vue';
import BaseAlertComponent from "@/components/Alert/Base/BaseAlertComponent.vue";

export default defineComponent({
	name: 'DangerAlert',
	components: {BaseAlertComponent},
	props: {
		errorMessage: {
			type: String,
			default: "",
		},
		errorTitle: {
			type: String,
			default: "",
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: "",
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['closeAlert'],
	setup(props, {emit}) {
		const closeAlert = () => {
			emit('closeAlert');
		};

		return {closeAlert};
	}
})
