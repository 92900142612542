import {User} from "@/services/boostpack/user/user.type";
import {AbstractChannel, MAIL_CHANNEL, TELEGRAM_CHANNEL} from "@/services/boostpack/channel/abstract_channel.type";
import {TelegramChannel} from "@/services/boostpack/channel/type/telegram_channel.type";
import {MailChannel} from "@/services/boostpack/channel/type/mail_channel.type";

/**
 *
 */
export class ChannelFactory {

	/* eslint-disable */
	/**
	 *
	 * @param item
	 */
	createChannelByResponse(item: Record<string, any>): AbstractChannel | TelegramChannel | MailChannel {
		let channel;

		const id = item.id;
		const type = item.type;
		const reference = item.reference;
		const userId = item.user_id;
		const user = this.createUserByData(item.user);
		const state = item.state;
		const data = item.data;
		const createdAt = item.created_at;
		const updatedAt = item.updated_at;

		if (type === TELEGRAM_CHANNEL) {
			const telegramVerifyUrl = item.telegram_verify_url;
			channel = new TelegramChannel(createdAt, data, id, type, reference, state, updatedAt, user, userId, telegramVerifyUrl);
		} else if (type === MAIL_CHANNEL) {
			channel = new MailChannel(createdAt, data, id, type, reference, state, updatedAt, user, userId);
		} else {
			throw new Error("Type " + type + " not allowed!");
		}
		return channel;
	}

	/**
	 *
	 * @param userData
	 */
	createUserByData(userData: Record<string, any>) {
		let user;
		for (const [key, item] of Object.entries(userData)) {
			const id = userData.id;
			const type = userData.type;
			const state = userData.state;
			const name = userData.name;
			const email = userData.email;
			const profilePhotoPath = userData.profile_photo_path;
			const profilePhotoUrl = userData.profile_photo_url;
			const emailVerifiedAt = userData.email_verified_at;
			const currentTeamId = userData.current_team_id;
			const createdAt = userData.created_at;
			const updatedAt = userData.updatedAt;
			user = new User(createdAt, currentTeamId, email, emailVerifiedAt, id, name, profilePhotoPath, profilePhotoUrl, state, type, updatedAt);
		}

		return user;
	}

}