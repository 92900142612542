import {AbstractChannel, TELEGRAM_CHANNEL} from "@/services/boostpack/channel/abstract_channel.type";
import {UserInterface} from "@/services/boostpack/user/user.interface";

export class TelegramChannel extends AbstractChannel {

	private telegramVerifyUrl: string;

	/**
	 *
	 * @param createdAt
	 * @param data
	 * @param id
	 * @param type
	 * @param reference
	 * @param state
	 * @param updatedAt
	 * @param user
	 * @param userId
	 * @param telegramVerifyUrl
	 */
	constructor(createdAt: Date, data: any, id: number, type: string, reference: string, state: string, updatedAt: Date, user: UserInterface, userId: number, telegramVerifyUrl: string) {
		super(createdAt, data, id, type, reference, state, updatedAt, user, userId);
		this.telegramVerifyUrl = telegramVerifyUrl;
	}

	getChannelType(): string {
		return TELEGRAM_CHANNEL;
	}

}