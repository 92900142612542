import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center my-4 space-x-3 sm:space-x-4 mx-12 my-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("form", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.code, (n, index) => {
      return _withDirectives((_openBlock(), _createBlock("input", {
        class: "bg-gray-200 h-12 w-9 text-center dark:text-gray-400 text-bp-dark dark:bg-gray-700 min-h-full block w-full text-lg rounded-2xl focus:outline-none",
        key: index,
        type: "number",
        pattern: "\\d*",
        id: 'input_' + index,
        maxlength: "1",
        "onUpdate:modelValue": ($event: any) => (_ctx.code[index] = $event),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onKeypress: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.isNumber && _ctx.isNumber(...args))),
        onKeydown: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleDelete && _ctx.handleDelete(...args)), ["delete"])),
        onPaste: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onPaste && _ctx.onPaste(...args)))
      }, null, 40, ["id", "onUpdate:modelValue"])), [
        [_vModelText, _ctx.code[index]]
      ])
    }), 128))
  ]))
}