
import {onMounted, ref} from 'vue';
import QrcodeVue from 'qrcode.vue';
import {ChannelService} from "@/services/api/channel/channel.service";
import DefaultButton from "@/components/Button/DefaultButton.vue";

export default {
	name: "ActivateTelegramChannel",
	components: {DefaultButton, QrcodeVue},
	props: {
		size: {
			type: Number,
			default: 150
		},
		data: {
			type: Object
		}
	},
	emits: ['onSuccessActivation', 'onError'],
	setup(props, {emit}) {
		const qrCodeLink = ref('');
		const startTime = ref(new Date())
		const channelState = ref();
		const activationTimeout = 300;
		const toleranceInSeconds = 100;
		const refreshInterval = ref();
		const activationExpired = ref();


		function clearRefreshInterval() {
			clearInterval(refreshInterval.value);
		}

		function getSecondsBetweenStartDateAndNow() {
			return Math.abs((new Date().getTime() - startTime.value.getTime()) / 1000);
		}

		function hasActivationTimeoutReached(includeTolerance = false): boolean {
			let timeOutInSeconds = activationTimeout;
			if (includeTolerance) {
				timeOutInSeconds += toleranceInSeconds;
			}
			return getSecondsBetweenStartDateAndNow() > timeOutInSeconds;
		}

		function onError() {
			clearRefreshInterval();
			emit('onError');
		}

		function onSuccessActivation() {
			clearRefreshInterval();
			emit('onSuccessActivation', props.data.uuid);
		}

		async function checkUserActivation() {
			channelState.value = await ChannelService.getChannelStateById(props.data.uuid);
			if (channelState.value === true) {
				setTimeout(function () {
					onSuccessActivation();
				}, 3000);
			} else if (hasActivationTimeoutReached(true)) {
				//TODO show retry button ---> resend activation --> make expire time longer
				clearRefreshInterval();
				activationExpired.value = true;
			} else if (!channelState.value && hasActivationTimeoutReached(false)) {
				clearRefreshInterval();
				activationExpired.value = true;
			}
			//getChannelStateById
		}

		function startRefreshInterval() {
			refreshInterval.value = setInterval(function () {
				checkUserActivation();
			}, 5000);
		}

		function extendActivation() {
			const resetPayload = {
				reference: props.data.reference,
			}
			ChannelService.resetPin(resetPayload).then(response => {
				//TODO check if response ok
				startTime.value = new Date();
				activationExpired.value = false;
				startRefreshInterval();
				return response;
			},
			error => {
				console.log(error);
				onError();
			});

		}

		function buildTelegramUrl(): string {
			return props.data.telegram_verify_url;
		}

		onMounted(() => {
			if (!props.data || !props.data.uuid) {
				onError();
				return false;
			}
			qrCodeLink.value = buildTelegramUrl();
			startRefreshInterval();
		})

		return {qrCodeLink, channelState, activationExpired, extendActivation};
	}
}
