
import {defineComponent, ref, onMounted, watch} from 'vue';
import MailChannel from "@/components/Boostpack/Subscribe/Channel/Mail/MailChannel.vue";
import TelegramChannel from "@/components/Boostpack/Subscribe/Channel/Telegram/TelegramChannel.vue";
import {MAIL_CHANNEL, TELEGRAM_CHANNEL} from "@/services/boostpack/channel/abstract_channel.type";
import InputFieldNoLabel from "@/components/Input/InputFieldNoLabel.vue";
import {KeyValueInterface} from "@/services/boostpack/core/key_value.interface";

//TODO full channel componet remove props
export default defineComponent({
	name: "AggregateChannelSelectBox",
	components: {InputFieldNoLabel, TelegramChannel, MailChannel},
	props: {
    selectedValues: {
      type: Array,
      default: () => [],
    },
    buttonValues: {
      type: Array,
    },
		value: {
			type: String,
			default: "",
		},
		size: {
			type: String,
			default: "150",
		},
		showValueAsText: {
			type: Boolean,
			default: false,
		},
		defaultSelectedChannel: {
			type: String,
			default: null,
		},
    inputPlaceholder: {
      type: String,
      default: "Input"
    },
		defaultEmail: {
			type: String,
			default: '',
		},
	},
	emits: ['updateSelectedChannel', 'updateEmail', 'inputSubmit', 'showLoading', 'hideLoading', 'onClickButton'],
	setup(props, {emit}) {
		const selectedChannel = ref("");
		const emailForChannel = ref("");

		function showLoading() {
			emit('showLoading');
		}

		function hideLoading() {
			emit('hideLoading');
		}

		function getMailChannelType() {
			return MAIL_CHANNEL;
		}

		function getTelegramChannelType() {
			return TELEGRAM_CHANNEL;
		}

		function isChannelAllowedToShow(channelType: string): boolean {
			return selectedChannel.value.length === 0 || selectedChannel.value === channelType;
		}

		function isTelegramChannelSelected(): boolean {
			return selectedChannel.value === TELEGRAM_CHANNEL;
		}

		function isMailChannelSelected(): boolean {
			return selectedChannel.value === MAIL_CHANNEL;
		}

		function updateSelectedChannel(channelType: string) {
			selectedChannel.value = channelType;
			emit('updateSelectedChannel', selectedChannel.value);
		}

		function updateEmail(email: string) {
			emailForChannel.value = email;
			emit('updateEmail', emailForChannel.value);
		}

		function inputSubmit() {
			updateEmail(emailForChannel.value);
			emit('inputSubmit', emailForChannel.value);
		}

		watch(() => props.defaultSelectedChannel, (newValue) => {
			if (newValue) {
				selectedChannel.value = newValue;
			}
		});

    const selectedValues: Array<KeyValueInterface> | any = ref(props.selectedValues);
    const selectButtonValues = ref(props.buttonValues);

    const onClickButton = () => {
      const values = Object.keys(selectedValues.value).map(key => selectedValues.value[key]);
      emit('onClickButton', values);
    };

    function isKeyActive(key): boolean {
      const buttonIndex = selectedValues.value.indexOf(key);
      return buttonIndex !== -1;
    }

    function toggleButton(key: any) {
      const removeButtonIndex = selectedValues.value.indexOf(key);
      if (removeButtonIndex !== -1) {
        selectedValues.value.splice(removeButtonIndex, 1);
      } else {
        selectedValues.value.push(key);
      }
      onClickButton();
    }

    watch(() => props.selectedValues, (newValue) => {
      if (newValue) {
        selectedValues.value = newValue;
      }
    });

    onMounted(() => {
      //
    })


		return {
			getMailChannelType,
			getTelegramChannelType,
			isChannelAllowedToShow,
			isTelegramChannelSelected,
			isMailChannelSelected,
			updateSelectedChannel,
			updateEmail,
			inputSubmit,
			showLoading,
			emailForChannel,
			hideLoading,onClickButton, toggleButton, isKeyActive, selectButtonValues
		};
	}
})
