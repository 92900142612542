import {UserInterface} from "@/services/boostpack/user/user.interface";
import {ChannelInterface} from "@/services/boostpack/channel/channel.interface";

export const TELEGRAM_CHANNEL = "telegram";
export const MAIL_CHANNEL = "mail";

export abstract class AbstractChannel implements ChannelInterface {
	createdAt: Date;
	data: any;
	id: number;
	type: string;
	reference: string;
	state: string;
	updatedAt: Date;
	user: UserInterface;
	userId: number;

	/**
	 *
	 * @param createdAt
	 * @param data
	 * @param id
	 * @param type
	 * @param reference
	 * @param state
	 * @param updatedAt
	 * @param user
	 * @param userId
	 */
	constructor(createdAt: Date, data: any, id: number, type: string, reference: string, state: string, updatedAt: Date, user: UserInterface, userId: number) {
		this.createdAt = createdAt;
		this.data = data;
		this.id = id;
		this.type = type;
		this.reference = reference;
		this.state = state;
		this.updatedAt = updatedAt;
		this.user = user;
		this.userId = userId;
	}


	abstract getChannelType(): string;

	getPin(): string {
		let result = "";

		const arrData = this.data;
		if (arrData && arrData["pin"]) {
			result = arrData["pin"];
		}

		return result;
	}

	getPinValidUntil(): string {
		let result = "";

		const arrData = this.data;
		if (arrData && arrData["pin_valid_until"]) {
			result = arrData["pin_valid_until"];
		}

		return result;
	}
}
