
import {defineComponent, onMounted, ref} from 'vue';
import {TELEGRAM_CHANNEL} from "@/services/boostpack/channel/abstract_channel.type";

export default defineComponent({
	name: "TelegramChannel",
	components: {},
	props: {
		value: {
			type: String,
			default: "",
		},
		size: {
			type: String,
			default: "150",
		},
		showValueAsText: {
			type: Boolean,
			default: false,
		},
		channelSelected: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['updateSelectedChannel'],
	setup(props, {emit}) {
		const selectedChannel = ref("");

		function selectTelegramAsChannel() {
			if (selectedChannel.value.length === 0) {
				selectedChannel.value = TELEGRAM_CHANNEL;
			} else {
				selectedChannel.value = "";
			}
			emit('updateSelectedChannel', selectedChannel.value);
		}

		return {selectTelegramAsChannel, selectedChannel};
	}
})
