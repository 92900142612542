
import {defineComponent, ref} from 'vue';
import {ChannelService} from "@/services/api/channel/channel.service";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import InputSplit from "@/components/Input/InputSplit.vue";
import LinkButton from "@/components/Button/LinkButton.vue";
import BaseAlertComponent from "@/components/Alert/Base/BaseAlertComponent.vue";

export default defineComponent({
	name: "ActivateMailChannel",
	components: {BaseAlertComponent, LinkButton, InputSplit, DefaultButton},
	props: {
		//MAIL ADDRESSE
		reference: {
			type: String,
			default: "",
		},
		data: {
			type: Object
		},
		showVerifyPinButton: {
			type: Boolean,
			default: false,
		},
		pinLength: {
			type: Number,
			default: 6,
		},
	},
	emits: ['onSuccessActivation', 'onError', 'showLoading', 'hideLoading'],
	setup(props, {emit}) {
		const pinCode = ref("");
		const validationInfo = ref("");
		const isVerifying = ref(false);
		const resendSuccessfully = ref(false);

		function onError(errorMessage = '') {
			emit('hideLoading');
			emit('onError', errorMessage);
		}

		function resendCode() {
			resendSuccessfully.value = false;
			const channelDataForActivation = {
				reference: props.data ? props.data.email : "",
			};

			try {
				ChannelService.resetPin(channelDataForActivation).then(response => {
						resendSuccessfully.value = true;
					},
					error => {
						if (error.response && error.response.data.error && error.response.data.error.includes('Integrity constraint violation: 1062 Duplicate')) {
							onError("You have already subscribed to the Boostpack with the same email. ");
						} else {
							onError("Error, Cannot reset Pin Code!");
						}
						isVerifying.value = false;
					}
				);
			} catch (error) {
				onError("Error cannot reset Pin Code!");
				isVerifying.value = false;
			}
		}

		function onSuccessActivation(userChannelUuid: string) {
			emit('onSuccessActivation', userChannelUuid);
		}

		function verifyPin(code: string | null = null) {
			//TODO trigger parent spinner
			emit('showLoading');
			isVerifying.value = true;
			if (code) {
				pinCode.value = code;
			}
			if (pinCode.value.length === 0) {
				validationInfo.value = "enter a valid pin!";
				return;
			}
			if (props.data && props.data.email.length === 0) {
				validationInfo.value = "enter a valid email!";
				return;
			}
			const channelDataForActivation = {
				reference: props.data ? props.data.email : "",
				pin: pinCode.value
			};

			try {
				ChannelService.activateChannel(channelDataForActivation).then(response => {
						//TODO save to storage , get uuid
						onSuccessActivation(props.data ? props.data.uuid : '');
					},
					error => {
						if (error.response && error.response.data.error && error.response.data.error.includes('Integrity constraint violation: 1062 Duplicate')) {
							onError("You have already subscribed to the Boostpack with the same email. ");
						} else {
							onError("Error, Cannot verify Pin Code!");
						}
						isVerifying.value = false;
					}
				);
			} catch (error) {
				onError("Error cannot activate mail channel!");
				isVerifying.value = false;
			}
		}

		return {verifyPin, resendCode, pinCode, isVerifying, resendSuccessfully};
	}
})
