
import {onMounted, ref, watch} from 'vue';
import ActivationAnimation from "@/components/Boostpack/Subscribe/Channel/ActivationAnimation.vue";
import ErrorActivationAnimation from "@/components/Boostpack/Subscribe/Channel/ErrorActivationAnimation.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";

export default {
	name: "SubscribeStep3",
	components: {DefaultButton, ErrorActivationAnimation, ActivationAnimation},
	props: {
		hasErrors: {
			type: Boolean,
			default: false,
		},
		subscriptionData: {
			type: Object,
			default: null,
		},
	},
	emits: ['closeCheckout'],
	setup(props, {emit}) {
		const subscribeHasError = ref(props.hasErrors);

		function close() {
			setTimeout(function () {
				emit('closeCheckout');
			}, 5000);
		}

		function retrySubscribeBoostpack()
		{
			const subscribeData =  {
				/* eslint-disable @typescript-eslint/camelcase */
				boostpack_id: props.subscriptionData.boostpackId,
				user_channel_uuid: props.subscriptionData.uuid,
				frequency: props.subscriptionData.duration,
			}
			if (props.subscriptionData.date && props.subscriptionData.date.length > 0) {
				subscribeData['start_date_with_time'] = props.subscriptionData.date;
			} else {
				subscribeData['start_with_time'] = props.subscriptionData.time;
			}

			BoostpackService.subscribe(subscribeData).then(response => {
					subscribeHasError.value = false;
					emit('onSuccess');
				},
				error => {
					console.log("Error", error);
					subscribeHasError.value = true;
				});
		}

		watch(() => props.hasErrors, (newValue) => subscribeHasError.value = newValue);


		onMounted(() => {
			if (!subscribeHasError.value) {
				close();
			}
		})


		return {
			subscribeHasError, retrySubscribeBoostpack
		};
	}
}
