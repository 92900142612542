
import {defineComponent, onMounted, ref} from 'vue';
import {useVModel} from '@/composable/input/useVModel.js';
import {nanoid} from 'nanoid'
import {ExclamationCircleIcon} from '@heroicons/vue/solid'

export default defineComponent({
	name: 'InputFieldNoLabel',
	components: {ExclamationCircleIcon},
	emits: ['update:email', 'update:password'],
	props: {
		value: String,
		inputType: {
			type: String,
			default: "input"
		},
		inputId: {
			type: String,
			default: ""
		},
		inputPlaceholder: {
			type: String,
			default: "Input"
		},
		requiredField: {
			type: Boolean,
			default: true
		},
		iWidth: {
			type: String,
			default: 'w-full sm:w-auto',
		},
		iHeight: {
			type: String,
			default: 'h-auto',
		},
		iRounded: {
			type: String,
			default: 'rounded-3xl',
		},
		textPosition: {
			type: String,
			default: 'text-center',
		},
		iPadding: {
			type: String,
			default: 'px-4 py-2',
		},
		iCss: {
			type: String,
			default: '',
		},
		cssClass: {
			type: String,
			default: 'my-2 sm:m-2',
		},
		errorMessage: {
			type: String,
			default: ""
		},
		autocompleteInput: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, {emit}) {
		const importFieldName = ref();
		const inputIdValue = ref(props.inputId ?? nanoid());

		onMounted(() => {
			importFieldName.value = "input_" + nanoid();
		})

		return {
			valueState: useVModel(props, 'value'),
			importFieldName,
			inputIdValue,
		};
	}
})
