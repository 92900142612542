
import {ref, onMounted, watch} from 'vue';
import {CheckIcon} from '@heroicons/vue/solid'

export default {
	name: "CheckoutNavBar",
	components: {CheckIcon},
	props: {
		steps: {
			type: Object,
			default: null
		},
		currentStep: {
			type: Number,
			default: 1
		},
	},
	emits: ['updateStep'],
	setup(props, {emit}) {
		const activeStep = ref();


		function updateStep(step: any, upcomingEvent = false) {
			if (activeStep.value.id != step.id && ((activeStep.value.allowedToGoBack && !upcomingEvent) || (activeStep.value.allowedToGoForward && upcomingEvent))) {
				activeStep.value = step;
				emit('updateStep', step.id);
			}
		}

		function setActiveStepByActiveStep(step: number) {
			activeStep.value = props.steps.find(({id}) => id === step);
		}

		watch(() => props.currentStep, (newValue) => activeStep.value = props.steps.find(({id}) => id === newValue));

		onMounted(() => {
			setActiveStepByActiveStep(props.currentStep);
		})

		return {
			activeStep, updateStep
		};
	}
}
