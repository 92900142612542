
export default {
  name: "ActivationAnimation",
  components: {},
  props: {
    channelState: {
      type: Boolean,
      default: true
    },
  },
  emits: [],
  setup() {
    return {};
  }
}
