
import {ref} from 'vue';
import {nanoid} from 'nanoid'

export default {
	name: "SubscribeHeader",
	components: {},
	props: {
    id: {type: String, default: nanoid()},
    title: {type: String},
    description: {type: String},
  },
	emits: [],
	setup(props) {
    const boostpackTitle = ref("");
    const categoryTitle = ref("");
    const boostpackDate = ref();
    const boostpackCreator = ref("");

    return {
      boostpackTitle,
      boostpackDate,
      boostpackCreator,
      categoryTitle,
    };
	}
}
