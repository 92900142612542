
import {ref, defineComponent, watch} from 'vue';
import {MAIL_CHANNEL, TELEGRAM_CHANNEL} from "@/services/boostpack/channel/abstract_channel.type";

export default defineComponent({
	name: "MailChannel",
	components: {},
	props: {
		value: {
			type: String,
			default: "",
		},
		defaultEmail: {
			type: String,
			default: "",
		},
		size: {
			type: String,
			default: "150",
		},
		showValueAsText: {
			type: Boolean,
			default: false,
		},
		channelSelected: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['updateSelectedChannel', 'updateEmail', 'inputSubmit'],
	setup(props, {emit}) {
		const selectedChannel = ref("");
		const email = ref(props.defaultEmail);

		watch(() => props.defaultEmail, (newValue) => {
			if (newValue && email.value.length === 0) {
				email.value = newValue;
			}
		});

		function selectMailAsChannel() {
			if (selectedChannel.value.length === 0) {
				selectedChannel.value = MAIL_CHANNEL;
			} else {
				selectedChannel.value = "";
			}
			emit('updateSelectedChannel', selectedChannel.value)
		}

		function updateEmail() {
			emit('updateEmail', email.value)
		}

		function inputSubmit() {
			emit('inputSubmit', email.value)
		}

		watch(email, (newEmail, oldEmail) => {
			if (newEmail !== oldEmail) {
				updateEmail();
			}
		})

		return {selectMailAsChannel, updateEmail, inputSubmit, email, selectedChannel};
	}
})
