
import {defineComponent, ref, onMounted, watch} from 'vue';
import {KeyValueInterface} from "@/services/boostpack/core/key_value.interface";

export default defineComponent({
	name: 'ButtonGroupSelectBox',
	components: {},
	props: {
		buttonValues: {
			type: Array,
		},
		inputPlaceholder: {
			type: String,
			default: "Input"
		},
		selectedValues: {
			type: Array,
			default: () => [],
		}
	},
	emits: ['onClickButton'],
	setup(props, {emit}) {
		const selectedValues: Array<KeyValueInterface> | any = ref(props.selectedValues);
		const selectButtonValues = ref(props.buttonValues);

		const onClickButton = () => {
			const values = Object.keys(selectedValues.value).map(key => selectedValues.value[key]);
			emit('onClickButton', values);
		};

		function isKeyActive(key): boolean {
			const buttonIndex = selectedValues.value.indexOf(key);
			return buttonIndex !== -1;
		}

		function toggleButton(key: any) {
			const removeButtonIndex = selectedValues.value.indexOf(key);
			if (removeButtonIndex !== -1) {
				selectedValues.value.splice(removeButtonIndex, 1);
			} else {
				selectedValues.value.push(key);
			}
			onClickButton();
		}

		watch(() => props.selectedValues, (newValue) => {
			if (newValue) {
				selectedValues.value = newValue;
			}
		});

		onMounted(() => {
			//
		})

		return {
			onClickButton, toggleButton, isKeyActive, selectButtonValues
		};
	}
})
