
import {onMounted, ref} from 'vue';
import {MAIL_CHANNEL, TELEGRAM_CHANNEL} from "@/services/boostpack/channel/abstract_channel.type";
import DefaultSpinner from "@/components/Loading/DefaultSpinner.vue";
import ActivateMailChannel from "@/components/Boostpack/Subscribe/Channel/Mail/ActivateMailChannel.vue";
import ActivateTelegramChannel from "@/components/Boostpack/Subscribe/Channel/Telegram/ActivateTelegramChannel.vue";
import DangerAlert from "@/components/Alert/Base/DangerAlert.vue";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";
import DefaultButton from "@/components/Button/DefaultButton.vue";

export default {
	name: "ChannelActivationStep2",
	components: {DefaultButton, DangerAlert, ActivateTelegramChannel, ActivateMailChannel, DefaultSpinner},
	props: {
		currentSelectedChannel: {
			type: String,
			default: "",
		},
		subscriptionData: {
			type: Object,
			default: null,
		},
		boostpackId: {
			type: Number,
			default: null,
		},
	},
	emits: ['updateSelectedChannel', 'updateEmail', 'onError', 'onSuccess', 'updateSubscribeData'],
	setup(props, {emit}) {
		const selectedChannel = ref(props.currentSelectedChannel);
		const errorMessage = ref("");
		const errorTitle = ref("");
		const isVerificationInProgress = ref(props.currentSelectedChannel === TELEGRAM_CHANNEL);
		const userChannelUuid = ref('');
		const showErrorAnimation = ref(false);
		const showActiveAnimation = ref(false);


		function isTelegramChannelSelected(): boolean {
			return selectedChannel.value === TELEGRAM_CHANNEL;
		}

		function isMailChannelSelected(): boolean {
			return selectedChannel.value === MAIL_CHANNEL;
		}

		function getStartDateWithTime(date: string|null, time: string): string {
			if (date) {
				return date + ' ' + time + ':00';
			} else {
				return time;
			}
		}

		function getBoostpackSubscribeData() {
			const data = {
				/* eslint-disable @typescript-eslint/camelcase */
				boostpack_id: props.boostpackId,
				user_channel_uuid: userChannelUuid.value,
				frequency: props.subscriptionData.duration, // TODO change to frequency "frequency": [1,5]
			};
			if (props.subscriptionData.date && props.subscriptionData.date.length > 0) {
				data['start_date_with_time'] = getStartDateWithTime(props.subscriptionData.date, props.subscriptionData.time);
			} else {
				data['start_with_time'] = getStartDateWithTime(null, props.subscriptionData.time);
			}

			return data;
		}

		function errorOnActivation(error: string) {
			showActiveAnimation.value = false;
			showErrorAnimation.value = true;
			errorMessage.value = error;
			emit('onError', errorMessage, null, false);
		}

		function activateChannel(user_channel_uuid: string) {
			//TODO subscribe boostpack
			showActiveAnimation.value = true;
			setTimeout(function () {
				userChannelUuid.value = user_channel_uuid;
				const boostpackSubscribeData = getBoostpackSubscribeData();
				emit('updateSubscribeData', boostpackSubscribeData);
				BoostpackService.subscribe(boostpackSubscribeData).then(response => {
						emit('onSuccess');
						return response;
					},
					error => {
						console.log("Error", error);
						errorOnActivation(error);
					});
			}, 2000)
		}

		onMounted(() => {
			if (!props.subscriptionData.nickname || !props.subscriptionData.date) {
				errorOnActivation('No Subscription Data found!')
			}
		})

		return {
			isTelegramChannelSelected,
			isMailChannelSelected,
			activateChannel,
			errorOnActivation,
			errorMessage,
			errorTitle,
			isVerificationInProgress,
			showErrorAnimation,
			showActiveAnimation
		};
	}
}
