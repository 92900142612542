
import {ref, onMounted} from 'vue';
import {MAIL_CHANNEL, TELEGRAM_CHANNEL} from "@/services/boostpack/channel/abstract_channel.type";
import InputField from "@/components/Input/InputField.vue";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import {ChannelService} from "@/services/api/channel/channel.service";
import ButtonGroupSelectBox from "@/components/Button/ButtonGroupSelectBox.vue";
import AggregateChannelSelectBox from "@/components/Boostpack/Subscribe/Channel/AggregateChannelSelectBox.vue";
import DangerAlert from "@/components/Alert/Base/DangerAlert.vue";

//TODO full channel componet remove props
export default {
	name: "UserInformationStep1",
	components: {DangerAlert, AggregateChannelSelectBox, ButtonGroupSelectBox, DefaultButton, InputField},
	props: {
    inputPlaceholder: {
      type: String,
      default: "Input"
    },
		value: {
			type: String,
			default: "",
		},
		size: {
			type: String,
			default: "150",
		},
		showValueAsText: {
			type: Boolean,
			default: false,
		},
		savedSubscriptionData: {
			type: Object,
			default: null,
		},
	},
	emits: ['onSuccess', 'onError', 'updateSelectedChannel'],
	setup(props, {emit}) {
		const firstStepForm = ref<any>(null);
		const selectedChannel = ref("");
		const emailForChannel = ref("");
		const subscriptionData = ref({
			nickname: "",
			email: "",
			date: new Date().toISOString().slice(0,10),
			time: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
			duration: [] as Array<number|any>,
			uuid: "",
			selectedChannel: "",
		});
		const errorMessage = ref("");
		const errorTitle = ref("");

		function getMailChannelType() {
			return MAIL_CHANNEL;
		}

		function getTelegramChannelType() {
			return TELEGRAM_CHANNEL;
		}

		function isChannelAllowedToShow(channelType: string): boolean {
			return selectedChannel.value.length === 0 || selectedChannel.value === channelType;
		}

		function isTelegramChannelSelected(): boolean {
			return selectedChannel.value === TELEGRAM_CHANNEL;
		}

		function isMailChannelSelected(): boolean {
			return selectedChannel.value === MAIL_CHANNEL;
		}

		function updateSelectedChannel(channelType: string) {
			selectedChannel.value = channelType;
			if (isTelegramChannelSelected()) {
				subscriptionData.value.email = "";
			}
			subscriptionData.value.selectedChannel = selectedChannel.value;
			emit('updateSelectedChannel', selectedChannel.value);
		}

		function updateEmail(email: string) {
			emailForChannel.value = email;
			subscriptionData.value.email = emailForChannel.value;
		}

		function updateDurationSelection(duration: Array<number>) {
			subscriptionData.value.duration = duration
		}

		function onSuccess(response) {
			emit('onSuccess', {...subscriptionData.value, ...response});
		}

		function onError(message: string, inlineError = false) {
			console.log(message);
			errorMessage.value = message;
			errorTitle.value = 'Error!';
			emit('onError', message, null, false, inlineError);
		}

		function getChannelDataForRequest() {
			return {
				/* eslint-disable @typescript-eslint/camelcase */
				channel_type: selectedChannel.value,
				email: subscriptionData.value.email
			}
		}

		function validateEmail(value: string): boolean {
			const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			return !!value.match(validRegex);
		}

		function validateSubscriptionData()
		{
			if (!selectedChannel.value) {
				onError('No channel selected!', true);
				return false;
			}
			if (!subscriptionData.value.email) {
				onError('Email not selected', true);
				return false;
			}
			if (!validateEmail(subscriptionData.value.email)) {
				onError('Invalid E-Mail', true);
				return false;
			}
			return true;
		}

		function createChannelForBoostpack() {
			if (!validateSubscriptionData()) {
				return false;
			}
			ChannelService.create(getChannelDataForRequest()).then(response => {
				onSuccess(response.data.data);
				return response;
			},
			error => {
				console.log(error)
				onError(error);
			});
		}

		function inputSubmit(email: string) {
			if (firstStepForm.value) {
				createChannelForBoostpack();
			}
		}

		onMounted(() => {
			if (props.savedSubscriptionData) {
				subscriptionData.value = props.savedSubscriptionData;
				if (subscriptionData.value.selectedChannel) {
					selectedChannel.value = subscriptionData.value.selectedChannel;
				}
				if (subscriptionData.value.email) {
					emailForChannel.value = subscriptionData.value.email;
				}
			}
		})

		return {
			getMailChannelType,
			getTelegramChannelType,
			isChannelAllowedToShow,
			isTelegramChannelSelected,
			isMailChannelSelected,
			updateSelectedChannel,
			updateEmail,
			subscriptionData,
			createChannelForBoostpack,
			updateDurationSelection,
			inputSubmit,
			firstStepForm,
			errorMessage,
			errorTitle
		};
	}
}
