
import {defineComponent, ref, watch} from 'vue';
import {nanoid} from 'nanoid'
import DefaultButton from "@/components/Button/DefaultButton.vue";
import BtnClose from "@/components/Nav/BtnClose.vue";
import DefaultVideoAd from "@/components/Ads/DefaultVideoAd.vue";
import UserInformationStep1 from "@/components/Boostpack/Subscribe/Checkout/UserInformationStep1.vue";
import DangerAlert from "@/components/Alert/Base/DangerAlert.vue";
import CheckoutNavBar from "@/components/Boostpack/Subscribe/Checkout/CheckoutNavBar.vue";
import ChannelActivationStep2 from "@/components/Boostpack/Subscribe/Checkout/ChannelActivationStep2.vue";
import SubscribeStep3 from "@/components/Boostpack/Subscribe/Checkout/SubscribeStep3.vue";
import SubscribeHeader from "@/components/Boostpack/Subscribe/SubscribeHeader.vue";
import BtnEdit from "@/components/Nav/BtnEdit.vue";
import useTranslation from "@/composable/translation/useTranslation";

export const CURRENT_STEP = 'current';
export const UPCOMING_STEP = 'upcoming';
export const COMPLETED_STEP = 'completed';

export default defineComponent({
	name: "BoostpackSubscribeCheckout",
	components: {
		BtnEdit,
		SubscribeStep3,
		SubscribeHeader,
		ChannelActivationStep2,
		CheckoutNavBar,
		DangerAlert,
		UserInformationStep1, BtnClose
	},
	props: {
		id: {type: String, default: nanoid()},
		title: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: false},
		showButton: {
			type: Boolean,
			default: true,
		},
		dialogIcon: {
			type: String,
			default: "smiley_icon",
		},
		boostpackId: {
			type: Number,
			default: null,
		},
		boostpackReference: {
			type: String,
			default: null,
		},
		DropdownTitle: {
			default: 'Title',
			type: String,
		},
		ListItem: {
			default: "ListItem",
			type: String,
		},
	},
	emits: ['closeCheckout'],
	setup(props, {emit}) {
		const checkoutVisible = ref(props.visible);
		const showAd = ref(false);
		const showHeader = ref(true);
		const activeStep = ref(1);
		const hasErrors: any = ref(false);
		const errorMessage = ref("");
		const errorTitle = ref("");
		const {t} = useTranslation();
		const steps = ref([
			{
				id: 1,
				name: t('modal.SubscribeDialog.step1'),
				href: '#',
				status: CURRENT_STEP,
				allowedToGoBack: false,
				allowedToGoForward: false,
				allowedToCloseBoostpack: true,
				allowedToShowButton: false,
			},
			{
				id: 2,
				name: t('modal.SubscribeDialog.step2'),
				href: '#',
				status: UPCOMING_STEP,
				allowedToGoBack: true,
				allowedToGoForward: false,
				allowedToCloseBoostpack: false,
				allowedToShowButton: false,
			},
			{
				id: 3,
				name: t('modal.SubscribeDialog.step3'),
				href: '#',
				status: UPCOMING_STEP,
				allowedToGoBack: false,
				allowedToGoForward: true,
				allowedToCloseBoostpack: true,
				allowedToShowButton: true,
			},
		]);
		const selectedChannel = ref("");
		const subscriptionData = ref({
			boostpackId: props.boostpackId,
			nickname: "",
			email: "",
			date: "",
			time: "",
			duration: "",
			uuid: "",
		});

		watch(() => props.visible, (newValue) => checkoutVisible.value = newValue);

		function resetCheckout() {
			subscriptionData.value = {
				boostpackId: props.boostpackId,
				nickname: "",
				email: "",
				date: "",
				time: "",
				duration: "",
				uuid: "",
			};
			activeStep.value = 1;
		}

		function getSelectedStep() {
			return steps.value.find(o => o.id == activeStep.value);
		}

		function isAllowedToShowButton() {
			let result = false;
			const selectedStep = getSelectedStep();
			if (selectedStep) {
				result = selectedStep.allowedToShowButton;
			}
			return result;
		}

		function isAllowedToGoBack() {
			let result = false;
			const selectedStep = getSelectedStep();
			if (selectedStep) {
				result = selectedStep.allowedToGoBack;
			}
			return result;
		}

		function isAllowedToCloseBoostpack() {
			let result = false;
			const selectedStep = getSelectedStep();
			if (selectedStep) {
				result = selectedStep.allowedToCloseBoostpack;
			}
			return result;
		}

		function cancelDialog() {
			//TODO rollback
			resetCheckout();
			checkoutVisible.value = false;
			emit('closeCheckout');
		}

		function changeStep(step) {
			if (step) {
				const oldStep = steps.value.find(({id}) => id === activeStep.value);
				if (oldStep) {
					if (oldStep.id < step) {
						oldStep.status = COMPLETED_STEP;
					} else {
						oldStep.status = UPCOMING_STEP;
					}
				}

				const newStep = steps.value.find(({id}) => id === step);
				if (newStep && (!oldStep || step > oldStep.id)) {
					newStep.status = CURRENT_STEP;
				}

				activeStep.value = step;
			}
		}

		function goToNextStep(subscriptionDataFromStep: any | null) {
			if (subscriptionDataFromStep) {
				subscriptionData.value = subscriptionDataFromStep;
				subscriptionData.value.boostpackId = props.boostpackId;
			}
			hasErrors.value = false;
			errorMessage.value = '';
			if (activeStep.value <= 2) {
				changeStep(activeStep.value + 1);
			}
		}

		function goBack() {
			errorMessage.value = '';
			if (activeStep.value > 1) {
				changeStep(activeStep.value - 1);
			}
		}

		function showError(errMsg: string, errorCode?: number, shouldGoOneStepBack = false, ignoreMessage = false) {
			hasErrors.value = true;
			if (!ignoreMessage) {
				errorMessage.value = errMsg;
			}
			if (shouldGoOneStepBack) {
				goBack()
			}
		}

		function updateSelectedChannel(channelType: string) {
			selectedChannel.value = channelType;
		}

		function toggleDialog() {
			checkoutVisible.value = !checkoutVisible.value;
		}

		function updateSubscribeData(updatedData) {
			const newSubscribeData = {...subscriptionData.value, updatedData};
			subscriptionData.value = newSubscribeData;
		}

		return {
			activeStep,
			steps,
			showAd,
			showHeader,
			checkoutVisible,
			errorMessage,
			errorTitle,
			selectedChannel,
			subscriptionData,
			cancelDialog,
			goToNextStep,
			showError,
			isAllowedToCloseBoostpack,
			isAllowedToShowButton,
			changeStep,
			isAllowedToGoBack,
			goBack,
			updateSelectedChannel,
			toggleDialog,
			hasErrors,
			updateSubscribeData,
		}
	}
})
